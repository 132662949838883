// FETCH ALL REFERRAL LIST
export const FETCH_REFERRAL_LIST_SUCCESS = "FETCH_REFERRAL_LIST_SUCCESS";
export const FETCH_REFERRAL_LIST_ERROR = "FETCH_REFERRAL_LIST_ERROR";

//PAGE_CHANGE Actions
export const REFERRAL_PAGE_CHANGE = "REFERRAL_PAGE_CHANGE";

//LIMIT_CHANGE Actions
export const REFERRAL_LIMIT_CHANGE = "REFERRAL_LIMIT_CHANGE";
export const REFERRAL_STATUS_CHANGE = "REFERRAL_STATUS_CHANGE";
